import React, { Component } from "react";
import Header from "../components/HeaderNou";

import Footer from "../components/Footer";
import { withRouter } from "react-router-dom";
import "../pages/css/AvisoLegal.css";

class AvisoLegal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header
          canviarLlenguatge={this.props.canviarLlenguatge}
          count={this.props.count}
          total={this.props.total}
          productes={this.props.productes}
          loggeat={this.props.iniciat}
          tancarSessio={this.props.tancarSessio}
        />
        <div className="container" style={{ paddingTop: "40px" }}>
          <div className="row">
            <h4 className="text-primary">
              <strong>AVISO LEGAL </strong>
            </h4>
            <div className="text">
              {" "}
              En cumplimiento de lo establecido en el artículo 10 de la Ley
              34/2002, de 11 de julio, de los Servicios de la Sociedad de la
              Información y Comercio Electrónico se exponen los siguientes datos
              identificativos del titular de la Plataforma, titularidad{" "}
              <strong> Embotits i pates artesans - Embutidos Aguiló S.L.</strong>
              <div className="datos">
                <strong>Nombre: </strong>Embotits i pates artesans - Embutidos Aguiló S.L. <br />
                <strong>Dirección: </strong>
                Avinguda d´Astúries, nº 4A <br />
                <strong>CIF:</strong> X-XXXXXX <br />
                <strong> Correo electrónico:</strong>{" "}
                <a
                  href="mailto:  laluna@la-luna.es"
                  className="text-primary"
                >
                  {" "}
                 laluna@la-luna.es{" "}
                </a>
              </div>
              Esta página Web se rige por la normativa exclusivamente aplicable
              en España, quedando sometida a ella, tanto nacionales como
              extranjeros que utilicen esta Web.
              <br /> <br /> El acceso a nuestra página Web por parte del Usuario
              es gratuito y está condicionado a la previa lectura y aceptación
              íntegra, expresa y sin reservas del presente Aviso Legal vigente
              en el momento del acceso, que rogamos lea detenidamente. El
              Usuario en el momento que utiliza nuestro portal, sus contenidos o
              servicios, acepta y se somete expresamente a las condiciones de
              este documento. Si el Usuario no estuviese de acuerdo con las
              presentes condiciones de uso, deberá abstenerse de utilizar este
              portal y operar por medio del mismo.
              <br /> <br /> En cualquier momento podremos modificar la
              presentación y configuración de nuestra Web, ampliar o reducir
              servicios, e incluso suprimirla de la Red, así como los servicios
              y contenidos prestados.{" "}
            </div>
            <h4 style={{ marginBottom: "0.5rem" }} className="text-primary">
              <strong> POLITICA DE ENVÍOS Y DEVOLUCIONES </strong>
            </h4>
            <div className="text">
              {" "}
              <strong> 1. Opciones de envío:</strong>
              <br />
              Los pedidos se entregarán en la dirección indicada en la solicitud
              o pueden ser recogidos en la tienda física. El comprador recibirá
              un mail confirmando el pedido realizado. <br /> <br />
              <strong> 2. Plazos de entrega:</strong>
              <br />
              El pedido llegará al destinatario en un límite de 30 días desde el
              momento de la recepción del mail de confirmación. <br />
              <div className="envios">
                <strong> - Envíos internacionales:</strong> <br />
                No se realizan envíos internacionales. <br />
                <strong> - Envíos nacionales:</strong> <br />
                Sólo se realizan envíos a la provincia de Illes Balears. Los
                costos de transporte para pedidos en Las Illes Baleares es de
                10€. <br />
                <br />
              </div>
              <strong> 3. Modificaciones de pedidos: </strong> <br />
              Para cambiar la dirección de entrega tiene que ponerse en contacto
              con la empresa a través del mail:{" "}
              <a
                href="mailto:  laluna@la-luna.es"
                className="text-primary"
              >
                laluna@la-luna.es{" "}
              </a>
              o el teléfono:{" "}
              <a href="tel:+34971886588" className="text-primary">
                971 886 588
              </a>{" "}
              <br />
              <br />
              <strong> 4. Problemas en la entrega: </strong> <br />
              Si tiene problemas con el pedido, o está dañado pónganse en
              contacto con la empresa a través del mail:
              <a
                href="mailto:  sabotiga@grupfruitssecs.com"
                className="text-primary"
              >
                {" "}
                laluna@la-luna.es
              </a>{" "}
              o el teléfono:{" "}
              <a href="tel:971630168" className="text-primary">
                971 63 01 68
              </a>{" "}
              <br />
              <br />
              <strong> 5. Cambios y devoluciones: </strong> <br />
              Al tratarse de productos de alimentación no se pueden realizar
              cambios o devoluciones.
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AvisoLegal);
