import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import Header from "../components/HeaderNou";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import "../pages/css/Fitxa.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Traduccio from "../components/Traduccio";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import noFoto from "../imatges/no_foto.png";
import CarosuelCards from "../components/CarouselCards";

import PA from "../imatges/pa_sobrassada.jpg";
import SOBRA from "../imatges/porc_negre.jpg";

import PROVA from "../imatges/prova.jpg";

import ImageGallery from "../components/imageGallerie/src/ImageGallery";
import "../components/imageGallerie/styles/css/image-gallery.css";

const data = [
  {
    original: `${PA}`,
    thumbnail: `${PA}`,
  },
  {
    original: `${SOBRA}`,
    thumbnail: `${SOBRA}`,
  },
];

class Fitxa extends Component {
  constructor(props) {
    super();
    this.state = {
      codi: "",
      id: "",
      info: [],
      infoProducte: [],
      familia: "",
      codiFam: "",
      preu: "",
      quant: 1,
      carregant: true,
      imatges: [],
      imatgesGallery: [],
      open: false,
    };
    if (props != null) {
      if (props.match.params.codi != null) {
        this.state.codi = props.match.params.codi;
      }
    }

    this.handleChange = this.handleChange.bind(this);
  }

  // Agafam la quantitat del producte.

  handleChange(valor) {
    this.setState({ quant: valor });
  }

  async componentDidMount() {
    // Peticions de la informació de l'articles i de les imatges.
    const lang = localStorage.getItem("idioma");

    const r = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/auth?user=${process.env.REACT_APP_USER}&pass=${process.env.REACT_APP_PASSWORD}`,
      {}
    );

    const token = r.data;

    localStorage.setItem("token", token.token);
    localStorage.setItem("tokenType", token.tokenType);

    const resposta = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_DOMAIN}/api/auth/refresh`,
      data: {
        token: localStorage.getItem("token"),
        session: {
          e: `${process.env.REACT_APP_EMPRESA_ID}`,
          i: `${process.env.REACT_APP_IDENTIFICADOR_ID}`,
        },
      },
      headers: {
        Authorization: `${localStorage.getItem(
          "tokenType"
        )} ${localStorage.getItem("token")}`,
      },
    });

    const tokenRefresh = resposta.data;
    localStorage.setItem("resposta", tokenRefresh.token);

    // let codi = "";
    // let darrerCaractId = this.state.codi.charAt(this.state.codi.length - 1);
    // if (darrerCaractId === "=") {
    //   codi = this.state.codi.substring(0, this.state.codi.length - 1);
    //   let penultimCaractId = codi.charAt(codi.length - 1);
    //   console.log("CARACT" + penultimCaractId);
    //   if (penultimCaractId === "=") {
    //     codi = codi.substring(0, codi.length - 1);
    //     console.log("CODI" + codi);
    //   } else {
    //     codi = codi;
    //   }
    // } else {
    //   codi = this.state.codi;
    // }

    const res = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/ecommerceCecoApp/articlesInformacio?query=article.codi=='${this.state.codi}'&page=0&size=100&lang=${lang}`,
      {
        headers: {
          Authorization: `${localStorage.getItem(
            "tokenType"
          )} ${localStorage.getItem("resposta")}`,
        },
      }
    );

    const info = res.data;

    if (info._embedded !== undefined) {
      let infor = info._embedded.articleInformacios;

      this.setState({ info: info._embedded.articleInformacios });
      let img = [];

      for (let i = 0; i < infor.length; i++) {
        const response = await axios
          .get(
            `${process.env.REACT_APP_API_DOMAIN_IMAGE}/${infor[i].rutaInforme}`,

            {
              responseType: "arraybuffer",
              headers: {
                Authorization: `${localStorage.getItem(
                  "tokenType"
                )} ${localStorage.getItem("resposta")}`,
              },
            }
          )
          .then((response) => {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );

            img.push("data:;base64," + base64);
          })
          .catch((error) => {
            img.push("null");
          });
      }

      this.setState({
        imatgesGallery: img.map((url) => ({
          original: `${url}`,
          thumbnail: `${url}`,
        })),
      });

      this.setState({ imatges: img });
    }

    const id = Buffer.from(
      `{"identificadorCodi":"${process.env.REACT_APP_IDENTIFICADOR_CODI}","codi":"${this.state.codi}"}`
    ).toString("base64");

    const resp = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/ecommerceCecoApp/articles/detail/${id}?lang=${lang}`,
      {
        headers: {
          Authorization: `${localStorage.getItem(
            "tokenType"
          )} ${localStorage.getItem("resposta")}`,
        },
      }
    );

    const infoProd = resp.data;

    const preu = infoProd?.preuAmbIva?.toFixed(2);

    this.setState({
      infoProducte: infoProd,
      familia: infoProd.familia.description,
      codiFam: infoProd.familia.pk.codi,
      preu: preu,
      carregant: false,
    });
  }

  render() {
    const that = this;
    if (this.state.carregant) {
      return (
        <div>
          <Header
            canviarLlenguatge={this.props.canviarLlenguatge}
            count={this.props.count}
            total={this.props.total}
            productes={this.props.productes}
            loggeat={this.props.iniciat}
            tancarSessio={this.props.tancarSessio}
          />
          <Helmet>
            <title> Grup Fruit Secs</title>
          </Helmet>
          <div className="container margeCarregant">
            <div className="text-center text-primary mt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Header
            canviarLlenguatge={this.props.canviarLlenguatge}
            count={this.props.count}
            total={this.props.total}
            productes={this.props.productes}
            loggeat={this.props.iniciat}
            tancarSessio={this.props.tancarSessio}
          />
          <Helmet>
            <title>
              {" "}
              {`${this.state.infoProducte.descripcioCurta} - Grup Fruit Secs`}{" "}
            </title>
          </Helmet>
          <div className="container-fluid fitxa containerFitxa">
            <div className="row">
              <div className="col-lg-6">
                {this.state.imatges.length === 0 ? (
                  <div className="carousel-wrapper">
                    <Carousel showStatus={false} showIndicators={false}>
                      <img src={noFoto} alt="Foto no disponible" />
                    </Carousel>
                  </div>
                ) : (
                  //   <div className="carousel-wrapper">
                  //     <Carousel showStatus={false} showIndicators={false}>
                  //       {this.state.imatges.map(function (articles, index) {
                  //         return (
                  //           <div key={index}>
                  //             <img src={articles} alt={index} />
                  //           </div>
                  //         );
                  //       })}
                  //     </Carousel>
                  //   </div>

                  <ImageGallery
                    items={this.state.imatgesGallery}
                    showPlayButton={false}
                    lazyLoad={true}
                    thumbnailPosition="left"
                    useBrowserFullscreen={false}
                  />
                )}
              </div>
              <div className="col-lg-6">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <Breadcrumb>
                        <Breadcrumb.Item href="/">
                          <Traduccio string="list.familias" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                          href={"/familia/" + this.state.codiFam}
                        >
                          {this.state.familia}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          <strong>
                            {" "}
                            {this.state.infoProducte.descripcioCurta}
                          </strong>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                      <h3 className="titolFitxa text-primary">
                        {this.state.infoProducte.descripcioCurta}
                      </h3>
                      <h5 className="descripcioFitxa">
                        {" "}
                        {this.state.infoProducte.descripcio}
                      </h5>
                      <div className="row rowPreu">
                        <div className="col-6">
                          <h2 className="preuFitxa text-primary">
                            {this.state.preu} €{" "}
                          </h2>
                          <p className="ivaFitxa">
                            {" "}
                            <Traduccio string="fitxa.iva" />
                          </p>
                        </div>
                      </div>
                      <div className="row rowPreu">
                        <div className="col-3 col-md-3 col-lg-2 mb-1">
                          <input
                            type="number"
                            min="1"
                            className="form-control number"
                            defaultValue="1"
                            onChange={(e) =>
                              this.handleChange(parseInt(e.target.value))
                            }
                          />
                        </div>
                        <div className="col-9 col-sm-10 col-md-4 col-lg-5 mb-1">
                          <a
                            className="btn btn-primary col"
                            onClick={() =>
                              this.props.afegirCistella(
                                this.state.codi,
                                this.state.quant,
                                this.state.preu,
                                this.state.infoProducte.decimalsPreu,
                                this.state.infoProducte.decimalsPreuIva,
                                this.state.infoProducte.descripcio,
                                this.state.infoProducte.descripcioCurta,
                                this.state.infoProducte.id,
                                this.state.imatges[0],
                                this.state.infoProducte.iva.id,
                                this.state.infoProducte.preuAmbIva,
                                this.state.infoProducte.pvp,
                                this.state.infoProducte.pvp
                              )
                            }
                          >
                            <Traduccio string="fitxa.añadir" />
                          </a>
                        </div>
                        <div className="col-sm-6 col-md-5 col-lg-5 mb-1">
                          <a href="/" className="btn btn-outline-primary col">
                            {" "}
                            <Traduccio string="carrito.seguirComprant" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h6 className="titolFitxa prodRelacionats">
              <Traduccio string="fitxa.relacionats" />
            </h6>
            <CarosuelCards
              codiFam={this.state.codiFam}
              codi={this.state.codi}
              afegirCistella={that.props.afegirCistella}
            />
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default withRouter(Fitxa);
