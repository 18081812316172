import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../components/css/Footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="main-footer">
        <div className="container">
          <p className="textFooter text-center">
            <strong>Embutidos Aguiló S.L.</strong>
            Avinguda d´Astúries, nº 4A 07100 Sóller, Mallorca.
            {""}
            <br />
            <a href="tel:+34971630168" className="text-white">
              Tel. 971 63 01 68 · Fax 971 63 35 63
            </a>{" "}
            <a
              className="text-white textFooter"
              href="https://http://www.la-luna.es/"
            >
              http://www.la-luna.es/
            </a>
            <br />
            <Link to="/aviso-legal" style={{ color: "white" }}>
              {" "}
              <strong>Aviso Legal</strong>
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
