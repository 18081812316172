export function emailVenedor(
  clientOrder,
  numero,
  preu,
  pedido,
  linies,
  dadesUsuari,
  nif,
  telefono,
  via,
  pais,
  prov,
  cp,
  domicili,
  num,
  pis,
  escala,
  porta,
  usuari,
  correu,
  nom,
  numDoc,
  p,
  provincia,
  poblacio,
  tipusVia,
  traDomicili,
  traEscala,
  traPis,
  traPorta,
  traNum,
  traEmail,
  traTel
) {
  let td = ``;

  for (var i = 0; i < linies?.length; i++) {
    var totalPerProducte = linies[i].unitats * linies[i].preuAmbIva;

    td =
      td +
      `<tr>
        <td style="padding: 5px 10px 5px 0" width="80%" align="left">
          <p>${linies[i].descripcio} (${linies[i].unitats})</p>
        </td>
        <td style="padding: 5px 0" width="20%" align="left">
          <p>${parseFloat(totalPerProducte.toString()).toFixed(2)} €</p>
        </td>
      </tr>`;
  }

  const emailVenedor = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
        <meta charset="UTF-8">
        <meta content="width=device-width, initial-scale=1" name="viewport">
        <meta name="x-apple-disable-message-reformatting">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta content="telephone=no" name="format-detection">
        <title></title>
     
        <style>
  
        #outlook a {
            padding: 0;
        }
        
        .ExternalClass {
            width: 100%;
        }
        
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        
        .es-button {
            mso-style-priority: 100 !important;
            text-decoration: none !important;
        }
        
        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        
        .es-desk-hidden {
            display: none;
            float: left;
            overflow: hidden;
            width: 0;
            max-height: 0;
            line-height: 0;
            mso-hide: all;
        }
        
        [data-ogsb] .es-button {
            border-width: 0 !important;
            padding: 15px 30px 15px 30px !important;
        }
        
        /*
        END OF IMPORTANT
        */
        s {
            text-decoration: line-through;
        }
        
        html,
        body {
            width: 100%;
            font-family: 'open sans', 'helvetica neue', helvetica, arial, sans-serif;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }
        
        table {
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-collapse: collapse;
            border-spacing: 0px;
        }
        
        table td,
        html,
        body,
        .es-wrapper {
            padding: 0;
            Margin: 0;
        }
        
        .es-content,
        .es-header,
        .es-footer {
            table-layout: fixed !important;
            width: 100%;
        }
        
        img {
            display: block;
            border: 0;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }
        
        table tr {
            border-collapse: collapse;
        }
        
        p,
        hr {
            Margin: 0;
        }
        
        h1,
        h2,
        h3,
        h4,
        h5 {
            Margin: 0;
            line-height: 120%;
            mso-line-height-rule: exactly;
            font-family: 'open sans', 'helvetica neue', helvetica, arial, sans-serif;
        }
        
        p,
        ul li,
        ol li,
        a {
            -webkit-text-size-adjust: none;
            -ms-text-size-adjust: none;
            mso-line-height-rule: exactly;
        }
        
        .es-left {
            float: left;
        }
        
        .es-right {
            float: right;
        }
        
        .es-p5 {
            padding: 5px;
        }
        
        .es-p5t {
            padding-top: 5px;
        }
        
        .es-p5b {
            padding-bottom: 5px;
        }
        
        .es-p5l {
            padding-left: 5px;
        }
        
        .es-p5r {
            padding-right: 5px;
        }
        
        .es-p10 {
            padding: 10px;
        }
        
        .es-p10t {
            padding-top: 10px;
        }
        
        .es-p10b {
            padding-bottom: 10px;
        }
        
        .es-p10l {
            padding-left: 10px;
        }
        
        .es-p10r {
            padding-right: 10px;
        }
        
        .es-p15 {
            padding: 15px;
        }
        
        .es-p15t {
            padding-top: 15px;
        }
        
        .es-p15b {
            padding-bottom: 15px;
        }
        
        .es-p15l {
            padding-left: 15px;
        }
        
        .es-p15r {
            padding-right: 15px;
        }
        
        .es-p20 {
            padding: 20px;
        }
        
        .es-p20t {
            padding-top: 20px;
        }
        
        .es-p20b {
            padding-bottom: 20px;
        }
        
        .es-p20l {
            padding-left: 20px;
        }
        
        .es-p20r {
            padding-right: 20px;
        }
        
        .es-p25 {
            padding: 25px;
        }
        
        .es-p25t {
            padding-top: 25px;
        }
        
        .es-p25b {
            padding-bottom: 25px;
        }
        
        .es-p25l {
            padding-left: 25px;
        }
        
        .es-p25r {
            padding-right: 25px;
        }
        
        .es-p30 {
            padding: 30px;
        }
        
        .es-p30t {
            padding-top: 30px;
        }
        
        .es-p30b {
            padding-bottom: 30px;
        }
        
        .es-p30l {
            padding-left: 30px;
        }
        
        .es-p30r {
            padding-right: 30px;
        }
        
        .es-p35 {
            padding: 35px;
        }
        
        .es-p35t {
            padding-top: 35px;
        }
        
        .es-p35b {
            padding-bottom: 35px;
        }
        
        .es-p35l {
            padding-left: 35px;
        }
        
        .es-p35r {
            padding-right: 35px;
        }
        
        .es-p40 {
            padding: 40px;
        }
        
        .es-p40t {
            padding-top: 40px;
        }
        
        .es-p40b {
            padding-bottom: 40px;
        }
        
        .es-p40l {
            padding-left: 40px;
        }
        
        .es-p40r {
            padding-right: 40px;
        }
        
        .es-menu td {
            border: 0;
        }
        
        .es-menu td a img {
            display: inline-block !important;
        }
        
        /* END CONFIG STYLES */
        a {
            text-decoration: none;
        }
        
        p,
        ul li,
        ol li {
            font-family: 'open sans', 'helvetica neue', helvetica, arial, sans-serif;
            line-height: 150%;
        }
        
        ul li,
        ol li {
            Margin-bottom: 15px;
        }
        
        .es-menu td a {
            text-decoration: none;
            display: block;
        }
        
        .es-wrapper {
            width: 100%;
            height: 100%;
            background-image: ;
            background-repeat: repeat;
            background-position: center top;
        }
        
        .es-wrapper-color {
            background-color: #eeeeee;
        }
        
        .es-header {
            background-color: transparent;
            background-image: ;
            background-repeat: repeat;
            background-position: center top;
        }
        
        .es-header-body {
            background-color: #044767;
        }
        
        .es-header-body p,
        .es-header-body ul li,
        .es-header-body ol li {
            color: #ffffff;
            font-size: 14px;
        }
        
        .es-header-body a {
            color: #ffffff;
            font-size: 14px;
        }
        
        .es-content-body {
            background-color: #ffffff;
        }
        
        .es-content-body p,
        .es-content-body ul li,
        .es-content-body ol li {
            color: #333333;
            font-size: 16px;
        }
        
        .es-content-body a {
            color: #ed8e20;
            font-size: 16px;
        }
        
        .es-footer {
            background-color: transparent;
            background-image: ;
            background-repeat: repeat;
            background-position: center top;
        }
        
        .es-footer-body {
            background-color: #ffffff;
        }
        
        .es-footer-body p,
        .es-footer-body ul li,
        .es-footer-body ol li {
            color: #333333;
            font-size: 14px;
        }
        
        .es-footer-body a {
            color: #333333;
            font-size: 14px;
        }
        
        .es-infoblock,
        .es-infoblock p,
        .es-infoblock ul li,
        .es-infoblock ol li {
            line-height: 120%;
            font-size: 12px;
            color: #cccccc;
        }
        
        .es-infoblock a {
            font-size: 12px;
            color: #cccccc;
        }
        
        h1 {
            font-size: 36px;
            font-style: normal;
            font-weight: bold;
            color: #333333;
        }
        
        h2 {
            font-size: 30px;
            font-style: normal;
            font-weight: bold;
            color: #333333;
        }
        
        h3 {
            font-size: 18px;
            font-style: normal;
            font-weight: normal;
            color: #333333;
        }
        
        .es-header-body h1 a,
        .es-content-body h1 a,
        .es-footer-body h1 a {
            font-size: 36px;
        }
        
        .es-header-body h2 a,
        .es-content-body h2 a,
        .es-footer-body h2 a {
            font-size: 30px;
        }
        
        .es-header-body h3 a,
        .es-content-body h3 a,
        .es-footer-body h3 a {
            font-size: 18px;
        }
        
        a.es-button,
        button.es-button {
            border-style: solid;
            border-color: #66b3b7;
            border-width: 15px 30px 15px 30px;
            display: inline-block;
            background: #66b3b7;
            border-radius: 5px;
            font-size: 18px;
            font-family: 'open sans', 'helvetica neue', helvetica, arial, sans-serif;
            font-weight: normal;
            font-style: normal;
            line-height: 120%;
            color: #ffffff;
            text-decoration: none;
            width: auto;
            text-align: center;
        }
        
        .es-button-border {
            border-style: solid solid solid solid;
            border-color: transparent transparent transparent transparent;
            background: #66b3b7;
            border-width: 0px 0px 0px 0px;
            display: inline-block;
            border-radius: 5px;
            width: auto;
        }
        
        /* RESPONSIVE STYLES Please do not delete and edit CSS styles below. If you don't need responsive layout, please delete this section. */
        @media only screen and (max-width: 600px) {
        
            p,
            ul li,
            ol li,
            a {
                line-height: 150% !important;
            }
        
            h1 {
                font-size: 32px !important;
                text-align: center;
                line-height: 120% !important;
            }
        
            h2 {
                font-size: 26px !important;
                text-align: center;
                line-height: 120% !important;
            }
        
            h3 {
                font-size: 20px !important;
                text-align: center;
                line-height: 120% !important;
            }
        
            .es-header-body h1 a,
            .es-content-body h1 a,
            .es-footer-body h1 a {
                font-size: 32px !important;
            }
        
            .es-header-body h2 a,
            .es-content-body h2 a,
            .es-footer-body h2 a {
                font-size: 26px !important;
            }
        
            .es-header-body h3 a,
            .es-content-body h3 a,
            .es-footer-body h3 a {
                font-size: 20px !important;
            }
        
            .es-menu td a {
                font-size: 16px !important;
            }
        
            .es-header-body p,
            .es-header-body ul li,
            .es-header-body ol li,
            .es-header-body a {
                font-size: 16px !important;
            }
        
            .es-content-body p,
            .es-content-body ul li,
            .es-content-body ol li,
            .es-content-body a {
                font-size: 16px !important;
            }
        
            .es-footer-body p,
            .es-footer-body ul li,
            .es-footer-body ol li,
            .es-footer-body a {
                font-size: 16px !important;
            }
        
            .es-infoblock p,
            .es-infoblock ul li,
            .es-infoblock ol li,
            .es-infoblock a {
                font-size: 12px !important;
            }
        
            *[class="gmail-fix"] {
                display: none !important;
            }
        
            .es-m-txt-c,
            .es-m-txt-c h1,
            .es-m-txt-c h2,
            .es-m-txt-c h3 {
                text-align: center !important;
            }
        
            .es-m-txt-r,
            .es-m-txt-r h1,
            .es-m-txt-r h2,
            .es-m-txt-r h3 {
                text-align: right !important;
            }
        
            .es-m-txt-l,
            .es-m-txt-l h1,
            .es-m-txt-l h2,
            .es-m-txt-l h3 {
                text-align: left !important;
            }
        
            .es-m-txt-r img,
            .es-m-txt-c img,
            .es-m-txt-l img {
                display: inline !important;
            }
        
            .es-button-border {
                display: inline-block !important;
            }
        
            a.es-button,
            button.es-button {
                font-size: 16px !important;
                display: inline-block !important;
                border-width: 15px 30px 15px 30px !important;
            }
        
            .es-btn-fw {
                border-width: 10px 0px !important;
                text-align: center !important;
            }
        
            .es-adaptive table,
            .es-btn-fw,
            .es-btn-fw-brdr,
            .es-left,
            .es-right {
                width: 100% !important;
            }
        
            .es-content table,
            .es-header table,
            .es-footer table,
            .es-content,
            .es-footer,
            .es-header {
                width: 100% !important;
                max-width: 600px !important;
            }
        
            .es-adapt-td {
                display: block !important;
                width: 100% !important;
            }
        
            .adapt-img {
                width: 100% !important;
                height: auto !important;
            }
        
            .es-m-p0 {
                padding: 0px !important;
            }
        
            .es-m-p0r {
                padding-right: 0px !important;
            }
        
            .es-m-p0l {
                padding-left: 0px !important;
            }
        
            .es-m-p0t {
                padding-top: 0px !important;
            }
        
            .es-m-p0b {
                padding-bottom: 0 !important;
            }
        
            .es-m-p20b {
                padding-bottom: 20px !important;
            }
        
            .es-mobile-hidden,
            .es-hidden {
                display: none !important;
            }
        
            tr.es-desk-hidden,
            td.es-desk-hidden,
            table.es-desk-hidden {
                width: auto !important;
                overflow: visible !important;
                float: none !important;
                max-height: inherit !important;
                line-height: inherit !important;
            }
        
            tr.es-desk-hidden {
                display: table-row !important;
            }
        
            table.es-desk-hidden {
                display: table !important;
            }
        
            td.es-desk-menu-hidden {
                display: table-cell !important;
            }
        
            .es-menu td {
                width: 1% !important;
            }
        
            table.es-table-not-adapt,
            .esd-block-html table {
                width: auto !important;
            }
        
            table.es-social {
                display: inline-block !important;
            }
        
            table.es-social td {
                display: inline-block !important;
            }
        }
        
        /* END RESPONSIVE STYLES */
        .es-p-default {
            padding-top: 20px;
            padding-right: 35px;
            padding-bottom: 0px;
            padding-left: 35px;
        }
        
        .es-p-all-default {
            padding: 0px;
        }
        </style>
  
    </head>
    
    <body>
        <div class="es-wrapper-color">
            <!--[if gte mso 9]>
                <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
                    <v:fill type="tile" color="#eeeeee"></v:fill>
                </v:background>
            <![endif]-->
            <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td class="esd-email-paddings" valign="top">
                            <table class="es-content esd-header-popover" cellspacing="0" cellpadding="0" align="center">
                                <tbody>
                                    <tr></tr>
                                    <tr>
                                        <td class="esd-stripe" esd-custom-block-id="7681" align="center">
                                            <table class="es-header-body" style="background-color: #ffffff;" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td class="esd-structure es-p20t es-p35r es-p35l" align="left">
                                                            <table cellpadding="0" cellspacing="0" width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td width="530" class="es-m-p0r esd-container-frame" align="center">
                                                                            <table cellpadding="0" cellspacing="0" width="100%">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td align="left" class="esd-block-image" style="font-size: 0px;"><a target="_blank"><img class="adapt-img" src="https://demo.stripocdn.email/content/guids/33bf8ed4-6cd5-4bc5-a6f6-7221a46cbe1f/images/icolaluna.png" alt style="display: block;" height="51"></a></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="es-content" cellspacing="0" cellpadding="0" align="center">
                                <tbody>
                                    <tr>
                                        <td class="esd-stripe" align="center">
                                            <table class="es-content-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td class="esd-structure es-p40t es-p35r es-p35l" align="left">
                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="esd-container-frame" width="530" valign="top" align="center">
                                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="esd-block-text es-p10b" align="center">
                                                                                            <h2>${clientOrder}</h2>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="es-content" cellspacing="0" cellpadding="0" align="center">
                                <tbody>
                                    <tr>
                                        <td class="esd-stripe" align="center">
                                            <table class="es-content-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td class="esd-structure es-p20t es-p35r es-p35l" align="left">
                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="esd-container-frame" width="530" valign="top" align="center">
                                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="esd-block-text es-p10t es-p10b es-p10r es-p10l" bgcolor="#eeeeee" align="left">
                                                                                            <table style="width: 500px;" class="cke_show_border" cellspacing="1" cellpadding="1" border="0" align="left">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td width="80%">
                                                                                                            <h4>${numero}</h4>
                                                                                                        </td>
                                                                                                        <td width="20%">
                                                                                                            <h4>${pedido}</h4>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="esd-structure es-p35r es-p35l" align="left">
                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="esd-container-frame" width="530" valign="top" align="center">
                                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="esd-block-text es-p10t es-p10b es-p10r es-p10l" align="left">
                                                                                            <table style="width: 500px;" class="cke_show_border" cellspacing="1" cellpadding="1" border="0" align="left">
                                                                                                <tbody>
                                                                                                   ${td}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="esd-structure es-p10t es-p35r es-p35l" align="left">
                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="esd-container-frame" width="530" valign="top" align="center">
                                                                            <table style="border-top: 3px solid #eeeeee; border-bottom: 3px solid #eeeeee;" width="100%" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="esd-block-text es-p15t es-p15b es-p10r es-p10l" align="left">
                                                                                            <table style="width: 500px;" class="cke_show_border" cellspacing="1" cellpadding="1" border="0" align="left">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td width="80%">
                                                                                                            <h4>TOTAL</h4>
                                                                                                        </td>
                                                                                                        <td width="20%">
                                                                                                            <h4>${preu?.toFixed(
                                                                                                              2
                                                                                                            )} €</h4>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="esd-structure es-p40t es-p40b es-p35r es-p35l" esd-custom-block-id="7796" align="left">
                                                            <table cellspacing="0" cellpadding="0" width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="esd-container-frame" width="530" align="left">
                                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="esd-block-text es-p15b" align="left">
                                                                                            <h4>${dadesUsuari}</h4>
                                                                                        </td>
                                                                                    </tr>
                                                                                    
                                                                                </tbody>
                                                                            </table>
                                                                            <table style="width: 100%;">
                                                                            <thead>
                                                                               <tr>
                                                                                    <th style ="font-weight: bold; text-align: left;"> ${nom} </th>
                                                
                                                                                    <th style ="font-weight: bold; text-align: left;">${numDoc}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                               <tr>
                                                                                <td style="text-align: left;">${
                                                                                  usuari ??
                                                                                  ""
                                                                                }</td>
                                                                  
                                                                                <td style="text-align: left;">${
                                                                                  nif ??
                                                                                  ""
                                                                                }</td> 
                                                                               </tr>
                                                                               
                                                                            </tbody>	
                                                                            <thead>
                                                                               <tr>
                                                                                  <th style ="font-weight: bold; text-align: left;">${p} </th>
                                                                                  <th style ="font-weight: bold; text-align: left;">${provincia} </th>
                                                                                  <th style ="font-weight: bold; text-align: left;">${poblacio} </th>         
                                                                               </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                               <tr>
                                                                                  <td style="text-align: left;">${
                                                                                    pais ??
                                                                                    ""
                                                                                  } </td>
                                                                                  <td style="text-align: left;">${
                                                                                    prov ??
                                                                                    ""
                                                                                  } </td>
                                                                                  <td style="text-align: left;">${
                                                                                    cp ??
                                                                                    ""
                                                                                  } </td>     
                                                                                </tr>
                                                                               
                                                                         </tbody>
                                                                         <thead>
                                                                               <tr>
                                                                                  <th style ="font-weight: bold; text-align: left;">${
                                                                                    tipusVia ??
                                                                                    ""
                                                                                  }</th>
                                                                                  <th style ="font-weight: bold; text-align: left;">${
                                                                                    traDomicili ??
                                                                                    ""
                                                                                  } </th>
                                                                                  <th style ="font-weight: bold; text-align: left;">${
                                                                                    traNum ??
                                                                                    ""
                                                                                  }</th>        
                                                                                  <th style ="font-weight: bold; text-align: left;">${
                                                                                    traEscala ??
                                                                                    ""
                                                                                  }</th>
                                                                                  <th style ="font-weight: bold; text-align: left;">${
                                                                                    traPis ??
                                                                                    ""
                                                                                  }</th>
                                                                                  <th style ="font-weight: bold; text-align: left;">${
                                                                                    traPorta ??
                                                                                    ""
                                                                                  } </th>
                                                                                </tr> 
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style="text-align: left;">${
                                                                                  via ??
                                                                                  ""
                                                                                }</td>
                                                                                <td style="text-align: left;">${
                                                                                  domicili ??
                                                                                  ""
                                                                                }  </td>
                                                                                <td style="text-align: left;">${
                                                                                  num ??
                                                                                  ""
                                                                                }  </td>
                                                                                <td style="text-align: left;">${
                                                                                  escala ??
                                                                                  ""
                                                                                }  </td>
                                                                                <td style="text-align: left;">${
                                                                                  pis ??
                                                                                  ""
                                                                                } </td>
                                                                                <td style="text-align: left;">${
                                                                                  porta ??
                                                                                  ""
                                                                                } </td>
                                                                        
                                                                            </tr>
                                                                             
                                                                        </tbody>
                                                                        <thead>
                                                                             <tr>
                                                                                <th style ="font-weight: bold; text-align: left;">${traEmail}</th>
                                                                              
                                                                                <th style ="font-weight: bold; text-align: left;">${traTel}</th>       
                                                                             </tr>
                                                                          </thead>
                                                                          <tbody>
                                                                             <tr>
                                                                                <td style="text-align: left;">${
                                                                                  correu ??
                                                                                  ""
                                                                                }</td>
                
                                                                                <td style="text-align: left;">${
                                                                                  telefono ??
                                                                                  ""
                                                                                }</td>      
                                                                             </tr>
                                                                          </tbody>
                                                                      </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table cellpadding="0" cellspacing="0" class="es-footer esd-footer-popover" align="center">
                                <tbody>
                                    <tr>
                                        <td class="esd-stripe" esd-custom-block-id="7684" align="center">
                                            <table class="es-footer-body" width="600" cellspacing="0" cellpadding="0" align="center" bgcolor="#00350c" style="background-color: #00350c;">
                                                <tbody>
                                                    <tr>
                                                        <td class="esd-structure es-p15t es-p40b es-p35r es-p35l" align="left">
                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="esd-container-frame" width="530" valign="top" align="center">
                                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="esd-block-image es-p15b" align="center" style="font-size: 0px;"><a target="_blank"><img src="https://demo.stripocdn.email/content/guids/33bf8ed4-6cd5-4bc5-a6f6-7221a46cbe1f/images/icolaluna.png" alt="Beretun logo" style="display: block;" title="Beretun logo" width="42"></a></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="esd-block-text" align="center">
                                                                                           <p style="color: #ffffff;">Embutidos Aguiló S.L.<br>Avinguda d´Astúries, nº 4A
                                                                                           <br>   07100 Sóller, Mallorca<br>  Tel. 971 63 01 68 · Fax 971 63 35 63
                                                                                           <br>Email: laluna@la-luna.es</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </body>
    
    </html>`;

  return emailVenedor;
}
