import React, { Component } from 'react';
import './css/LlistaPedido.css';
import axios from 'axios';


class LlistaPedidoGastos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            preu: "",
            titol: "",
            quant: "",

        }

    }


    async componentDidMount() {

        const preu = this.props.preu.toFixed(2);
        const total = preu * this.props.quant;
       

        let titol = "";

        if (this.props.desc.length > 20) {
            titol = this.props.desc.substring(0, 20);
            titol = titol + "...";
        } else {
            titol = this.props.desc;
        }



        this.setState({ preu: preu, titol: titol, quant: this.props.quant, id: this.props.id, totalArticle : total.toFixed(2)});
    

    }

    render() {


        return (
            <>
                <tr>
                    <td className="titolsLlista">
                        {this.state.titol}
                    </td>
                    <td>
                        {this.state.quant}
                    </td>
                    <td>
                    {this.state.preu} €
                    </td>
                    <td>
                    {this.state.totalArticle} €
                    </td>

                </tr>




            </>
        );
    }
}

export default LlistaPedidoGastos;