import React, { Component } from "react";
import { Link } from "react-router-dom";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

import "./css/CardNova.css";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import noFoto from "../imatges/no_foto.png";
import PA from "../imatges/pa_sobrassada.jpg";
import SOBRA from "../imatges/porc_negre.jpg";
import CheckIcon from '@material-ui/icons/Check';

import axios from "axios";

class CardIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preu: "",
      comprat: false,
      foto1: "",
      foto2: "",
      foto: false,
      source: "",
      source2: "",
      sourceImg:"",
    };
  }

  async componentDidMount() {
    const res = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/ecommerceCecoApp/articlesInformacio?query=article.codi==${this.props.codi}&page=0&size=100`,
      {
        headers: {
          Authorization: `${localStorage.getItem(
            "tokenType"
          )} ${localStorage.getItem("resposta")}`,
        },
      }
    );

    const info = res.data;
    const preu = this.props.preu?.toFixed(2);
    this.setState({ preu: preu });

    if (info.page.totalElements == 1) {
      var foto = info._embedded.articleInformacios[0].rutaInforme;
      this.setState({ foto1: foto, foto2: foto });

      const response = await axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN_IMAGE}/${foto}`,

          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `${localStorage.getItem(
                "tokenType"
              )} ${localStorage.getItem("resposta")}`,
            },
          }
        )
        .then((response) => {
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          this.setState({
            sourceImg: response.data,
            source: "data:;base64," + base64,
            source2: "data:;base64," + base64,
          });
        });
       } else{
         if(info.page.totalElements >= 2) {
        var foto = info._embedded.articleInformacios[0]?.rutaInforme;
        var foto2 = info._embedded.articleInformacios[1].rutaInforme;
   

        this.setState({ foto1: foto, foto2: foto2 });

        const response = await axios
          .get(
            `${process.env.REACT_APP_API_DOMAIN_IMAGE}/${foto}`,

            {
              responseType: "arraybuffer",
              headers: {
                Authorization: `${localStorage.getItem(
                  "tokenType"
                )} ${localStorage.getItem("resposta")}`,
              },
            }
          )
          .then((response) => {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            this.setState({
              sourceImg: response.data,
              source: "data:;base64," + base64,
            });
          });

        const response2 = await axios
          .get(
            `${process.env.REACT_APP_API_DOMAIN_IMAGE}/${foto2}`,

            {
              responseType: "arraybuffer",
              headers: {
                Authorization: `${localStorage.getItem(
                  "tokenType"
                )} ${localStorage.getItem("resposta")}`,
              },
            }
          )
          .then((response) => {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            
            this.setState({
              source2: "data:;base64," + base64,
            });
          });
      } else {
        this.setState({ foto: true });
      }
    }

  
  }

  canviarIcon() {
    this.setState({ comprat: true });
    setTimeout(() => this.setState({ comprat: false }), 500);
  }

  render() {
   
    return (
      <div>
        <div className="cardsNoves">
          <div className="card card1">
            <a
              href={"/producte/" + this.props.codi}
              className="linkCard streched-link"
            >
              <div className="img-container">
                 <img 
                //  src={this.state.source2} 
                //  src={PA} 
                 src={this.state.source2 === "" ? noFoto : this.state.source2}
                  className="card-img-top h-100 img-main"
                  alt={this.props.desc}
                />
                <img 
                // src={this.state.source}
                  src={this.state.source === "" ? noFoto : this.state.source}
                  // src={SOBRA}
                  className="card-img-top h-100 img-secondary"
                  alt={this.props.desc}
                />
              </div>

              <h6 className="titol text-primary titol1">{this.props.desc}</h6>
              <div className="text-muted descripcioFam">
                {this.props.familia}
              </div>
              <h6 className="preuCard">{this.state.preu}€ </h6>
            </a>
          </div>

          <a
            className="btn btn-outline-primary botoComprar mb-2"
            id={this.props.codi}
            onClick={() =>
              
              this.props.afegirCistella(
                this.props.codi,
                1,
                this.state.preu,
                this.props.decimalsPreuCataleg,
                this.props.decimalsPreuSenseIvaCataleg,
                this.props.descripcio,
                this.props.desc,
                this.props.id,
                this.state.source,
                this.props.ivaId,
                this.props.preuCataleg,
                this.props.preuSenseIvaCataleg,
                this.props.preuSenseIva
              ) + this.canviarIcon(this.props.codi)
            }
          >
            {this.state.comprat ? (
              <CheckIcon />
            ) : (
              <AddShoppingCartIcon />
            )}
          </a>
        </div>
      </div>
    );
  }
}

export default CardIndex;
